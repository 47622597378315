.BylineSection {
    display: flex;
    flex-direction: column;
    margin: 50px 0px 50px 0px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-shrink: 1;
    .Title {
        max-width: 96vw;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;
        h2 {
            font-family: "Georgia";
            font-style: italic;
            font-weight: 700;
            font-size: 29px;
            line-height: 29px;
            color: #000000;
            margin-bottom: 0px;
            white-space: nowrap;
        }
    }
    .Bylines {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 50px;
        padding: 30px 2vw 30px 2vw;
        justify-content: center;
    }
    hr {
        max-width: 250px;
        width: 48vw;
        background-color: rgb(0, 0, 0);
        height: 1.5px;
        margin: 0px;
    }
}

@media (max-width: 1210px) {
    .BylineSection {
        margin: 30px 0px 40px 0px;
        gap: 10px;
        .Bylines {
            gap: 30px;
        }
    }
}

@media (max-width: 960px) {
    .BylineSection {
        .Bylines {
            justify-content: start;
            overflow-x: scroll;
            gap: 3vw;
        }
    }
}

@media (max-width: 945px) {
    .BylineSection {
        .Title {
            hr {
                max-width: 185px;
                width: 96vw;
            }
        }
        hr {
            width: 96vw;
        }
    }
}
