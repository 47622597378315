.Hub {
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-height: 600px;
    max-width: 700px;
    height: 82.2857vw;
    width: 96vw;
    display: flex;
    flex-direction: column;
    .Page {
        display: none;
    }
    .Page-Active {
        display: block;
        flex-grow: 1;
        overflow: hidden;
    }
    .TabBar {
        display: flex;
        padding: 0px 20px 0px 20px;
        gap: 20px;
        .Button {
            border: none;
            background-color: white;
            height: 40px;
            p {
                font-family: "Lexend";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #5c5c5c;
                margin: 0px;
            }
        }
        .Button-Active {
            border-bottom: solid 3px #000000;
            p {
                color: #000000;
            }
        }
    }
    .About {
        display: flex;
        padding: 30px;
        gap: 40px;
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            url("../../assets/other/Background.png");
        background-size: cover;
        background-position: center;
        height: 100%;
        border-radius: 0px 0px 5px 5px;
        .Info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            img {
                width: 100%;
                border-radius: 10px;
                filter: grayscale(1);
            }
            .Contact {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;
                p {
                    font-family: "Georgia";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 17px;
                    color: #ffffff;
                    margin-bottom: 0px;
                }
            }
        }
        .Bio {
            p {
                font-family: "Lexend";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 17px;
                color: #ffffff;
            }
        }
    }
}
@media (max-width: 715px) {
    .Hub {
        .TabBar {
            .Button {
                p {
                    font-size: 14px;
                    line-height: 15px;
                }
            }
        }
        .About {
            flex-direction: column;
            overflow-y: scroll;
            gap: 20px;
            .Info {
                flex-direction: row;
                img {
                    width: 26%;
                }
                .Contact {
                    gap: 10px;
                    p {
                        font-size: 14px;
                        line-height: 15px;
                    }
                }
            }
            .Bio {
                p {
                    font-size: 14px;
                    line-height: 15px;
                }
            }
        }
    }
}
