.Storyline {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    height: 100%;
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .Story {
        box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.05);
    }
    .Headline {
        margin: 0px;
        padding: 20px 20px 0px;
        width: 100%;
        background-size: cover !important;
        background-position: center !important;
        display: flex;
        border-radius: 5px;
        h2 {
            font-family: "Georgia";
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 28px;
            color: #ffffff;
            height: 153px;
        }
        p {
            font-family: "Georgia";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #525252;
            width: 100%;
            margin-top: auto;
        }
    }
    .Paragraphs {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 20px;
        gap: 20px;
        p {
            font-family: "Georgia";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: justify;
            color: #252525;
            margin: 0px;
        }
    }
    .Series {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 20px 20px;
        gap: 10px;
        width: 100%;
        flex-grow: 1;
        .Title {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            h2 {
                font-family: "Lexend";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                margin: 0px;
            }
            p {
                font-family: "Georgia";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #464646;
                margin: 0px;
            }
        }
        .Sub-Stories {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            flex-grow: 1;
            width: 100%;
            align-items: center;
            height: 10px;
            .Related-Coverage {
                align-self: start;
                p {
                    font-family: "Lexend";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 18px;
                    color: #000000;
                    margin: 0px;
                }
                ul {
                    padding-left: 20px;
                }
                li {
                    line-height: 16px;
                }
                a {
                    text-decoration: none;
                    color: #000000;
                    font-family: "Georgia";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 10px;
                    letter-spacing: -0.05em;
                }
            }
        }
    }
}
@media (max-width: 585px) {
    .Storyline {
        .Series {
            .Sub-Stories {
                height: auto;
                .Story {
                    img {
                        width: 100%;
                    }
                }
                .Related-Coverage {
                    height: 130px;
                    overflow-y: scroll;
                }
            }
        }
    }
}
@media (max-width: 499px) {
    .Storyline {
        .Series {
            .Sub-Stories {
                height: auto;
                .Story {
                    img {
                        width: 100%;
                    }
                }
                .Related-Coverage {
                    height: 90px;
                }
            }
        }
    }
}
