.App {
    .Header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .Hero {
        width: 100%;
        max-width: 1500px;
        display: flex;
        flex-direction: row;
        margin: 50px auto 70px auto;
        align-items: center;
        justify-content: center;
        gap: 5%;
        @media (max-width: 1200px) {
            flex-direction: column-reverse;
            gap: 2vw;
            margin: 20px auto 2vw auto !important;
        }
    }
    .Stories {
        justify-content: center;
        display: grid;
        grid-template-columns: 275px 275px 275px;
        grid-template-rows: 275px 275px;
        grid-gap: 30px;
        @media (max-width: 945px) {
            grid-template-columns: 275px 275px;
            grid-template-rows: 275px 275px 275px;
            grid-gap: 2vw;
        }
        @media (max-width: 585px) {
            grid-template-columns: 47vw 47vw;
            grid-template-rows: 47vw 47vw 47vw;
            grid-gap: 2vw;
        }
    }
    .StorylineStories {
        justify-content: center;
        display: grid;
        grid-template-columns: 275px 275px 275px;
        grid-template-rows: repeat(5, 275px);
        gap: 30px;
        grid-auto-flow: row;
        grid-template-areas:
            ". . ."
            "Storyline Storyline ."
            "Storyline Storyline ."
            "Storyline Storyline ."
            ". . .";
        @media (max-width: 945px) {
            grid-template-columns: 275px 275px;
            grid-template-rows: repeat(8, 275px);
            gap: 30px;
            grid-template-areas:
                "Storyline Storyline"
                "Storyline Storyline"
                "Storyline Storyline"
                ". ."
                ". ."
                ". ."
                ". ."
                ". .";
        }
        @media (max-width: 585px) {
            grid-template-columns: 47vw 47vw;
            grid-template-rows: auto repeat(5, 47vw);
            grid-gap: 2vw;
            grid-template-areas:
                "Storyline Storyline"
                ". ."
                ". ."
                ". ."
                ". ."
                ". .";
        }
    }

    .Storyline {
        grid-area: Storyline;
    }
}
