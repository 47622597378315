.carousel {
    height: 100%;
    .carousel-indicators {
        bottom: calc(100% - 27px);
        margin: 0px;
        button {
            width: 100%;
            border-top: 3px solid transparent;
            border-bottom: 20px solid transparent;
        }
    }
    .carousel-inner {
        border-radius: 0px 0px 5px 5px;
        height: 100%;
        .carousel-item {
            height: 100%;
            img {
                position: absolute;
                bottom: 5%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .carousel-caption {
                background-color: white;
                padding: 10px 20px 10px 20px;
                margin: 0px;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: left;
                height: fit-content;
                p {
                    margin-bottom: 0px;
                    font-family: "Georgia";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 17px;
                    color: #000000;
                }
            }
        }
    }
}
